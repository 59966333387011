<template>
  <div ref="appReference" :class="appClasses" :style="appStyle">
    <FullPageLoader v-if="fullAppLoading.flag" :options="fullAppLoading" />
    <!--    <div-->
    <!--      v-if="showAppLicenseBanner"-->
    <!--      :class="{-->
    <!--        'ml-LayoutHeader': true,-->
    <!--        'ml-LayoutHeader-menu-pinned': menuPinned && !fullScreen-->
    <!--      }"-->
    <!--    >-->
    <!--      <AppBanner>-->
    <!--        <strong> {{ topLicenseBannerContent }} </strong>-->
    <!--      </AppBanner>-->
    <!--    </div>-->

    <aside
      v-if="isShowAside"
      v-show="!fullScreen"
      :class="{
        'app-Aside-pinned': menuPinned,
        'app-Aside-showed': menuShowed
      }"
      class="app-Aside"
      @mouseleave="onAsideMouseLeave"
    >
      <AppMenu>
        <template #jiraAppLicenseBanner>
          <JiraAppLicenseBanner v-if="showAppLicenseBanner">
            {{ topLicenseBannerContent }}
          </JiraAppLicenseBanner>
        </template>
      </AppMenu>
    </aside>

    <main :class="pageClasses">
      <template v-if="isInstanceBlocked">
        <AppLostConnection v-if="instanceStateId === INSTANCE_STATES.CONNECTION_LOST.id" />

        <AppConnectionDisconnectionInProgress
          v-if="
            instanceStateId === INSTANCE_STATES.CONNECTION_IN_PROGRESS.id ||
            instanceStateId === INSTANCE_STATES.DISCONNECTION_IN_PROGRESS.id
          "
          :is-disconnection="instanceStateId === INSTANCE_STATES.DISCONNECTION_IN_PROGRESS.id"
        />
      </template>
      <template v-else-if="limitLicenseAccess">
        <AppBillingMissing v-if="isWebApp || isCrossPlatformApp" />
        <AppLicenseExpired v-else :is-plugin-server="isPluginServer" />
      </template>

      <template v-else>
        <vue-progress-bar class="app-ProgressBar" />
        <router-view v-slot="{ Component }">
          <component
            :is="Component"
            :is-show-create-organization="isShowCreateOrganization"
            :is-show-onboarding="isShowOnboarding"
            @close-onboarding="isShowOnboarding = false"
            @close-create-organization="onCloseCreateOrganization"
          />
        </router-view>

        <StoryLaneOnboarding v-if="isWorkspaceSelected && !isShowOnboarding && !isPluginServer" />
      </template>
    </main>

    <!--    <AppTour v-if="isShowTour" />-->

    <AppNotifications />

    <FeedbackModal :show="isShowFeedbackModal" @close="closeFeedbackModal" />

    <portal-target multiple name="modal-windows" />
  </div>
</template>

<script>
// eslint-disable-next-line import/order
import { defineComponent } from 'vue'
// eslint-disable-next-line import/order
import { EVENT_CATEGORIES } from '@/tracking/amplitude-helpers'

export default defineComponent({
  name: 'App',

  created() {
    //  [App.vue specific] When App.vue is first loaded start the progress bar
    this.$Progress.start()
    //  hook the progress bar to start before we move router-view
    this.$router.beforeEach((to, from, next) => {
      if (to.name !== from.name) {
        //  start the progress bar
        this.$Progress.start()
        //  continue to next page
      }
      next()
    })
    //  hook the progress bar to finish after we've finished moving router-view
    this.$router.afterEach(() => {
      //  finish the progress bar
      this.$Progress.finish()
    })
  },

  mounted() {
    this.$Progress.finish()
  }
})
</script>

<script setup>
// import { clarity } from 'clarity-js'
import dayjs from 'dayjs'
import { isEmpty, uniq } from 'lodash'
import { computed, onMounted, onBeforeUnmount, watch, nextTick, ref, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

import { useExternalEnvironmentElementsHeight } from '@/composables/app-vue'
import { tracker } from '@/tracking/amplitude'
import { EVENT_KEYS, EVENT_NAMES } from '@/tracking/gtm-helpers'
import { gtmTracker } from '@/tracking/gtm-tracking'
import { MENU_PINNED_STATUS_LS_KEY } from '@/utils/app-menu'
import { addDaysToNow } from '@/utils/date'
import { IS_SAFARI_CLASS, isSafari, STYLED_SCROLLBAR_CLASS } from '@/utils/general'
import {
  appPlatformInjectionKey,
  isCrossPlatformAppInjectionKey,
  isJiraAppInjectionKey,
  isWebAppInjectionKey
} from '@/utils/injection-keys'
import { INSTANCE_STATES, isInstanceStateBlocked } from '@/utils/instance-states'
import { isEscape } from '@/utils/key-codes'
import { raiseKeydownEvent } from '@/utils/keyevents'
import { LICENSE_TYPES } from '@/utils/license-types'
import { NOTIFICATION_DURATIONS, NOTIFICATION_TYPES, showNotify } from '@/utils/notify'
import {
  // updateStorageByKey,
  isLocalStorageAvailable,
  readFromStorageByKey
} from '@/utils/persist'
import {
  DEFAULT_USERS_SETTINGS,
  FEEDBACK_MODAL_ID,
  RELEASE_UPDATES_MODAL_ID,
  USER_SETTINGS_MAPPER
} from '@/utils/user-settings'
import { JIRA_CLOUD_API, JIRA_SERVER_API } from '@jira/util'
import { REQUIRED_APP_OPENED_COUNT_TO_SHOW_MODAL } from '@jira/utils/feedback-modal'
import { IS_PRODUCTION } from '@root/app-modes'
import { IS_DEVELOPMENT } from '@root/app-modes'
import { APP_PLATFORMS } from '@root/app-platforms'
import { PLUGIN_OPTIONS_KEYS } from '@root/template-options-keys'
import AppBillingMissing from '@shared-modules/views/workspaces/settings/billing/AppBillingMissing'

// import AppBanner from '@/components/AppBanner'
// import MessageModal from '@/components/feedback/MessageModal'
// import RateModal from '@/components/feedback/RateModal'
// import ThanksModal from '@/components/feedback/ThanksModal'

import AppLicenseExpired from '@/components/AppLicenseExpired'
import AppMenu from '@/components/AppMenu/AppMenu'
// import AppTour from '@/components/AppTour'
import StoryLaneOnboarding from '@/components/StoryLaneOnboarding/StoryLaneOnboarding'
import JiraAppLicenseBanner from '@/components/ui/AppBanners/JiraAppLicenseBanner'
import AppNotifications from '@/components/ui/AppNotifications/AppNotifications'
import FeedbackModal from '@/components/ui/FeedbackModal/FeedbackModal'
import FullPageLoader from '@/components/ui/FullPageLoader'
import AppConnectionDisconnectionInProgress from '@/views/workspaces/settings/integrations/AppConnectionDisconnectionInProgress'
import AppLostConnection from '@/views/workspaces/settings/integrations/AppLostConnection'

// used local var instead of env till added to jenkins
const VUE_APP_DISABLE_FEEDBACK_MODAL_FOR_JIRA = false
// used local var instead of env till added to jenkins
const VUE_APP_DISABLE_FEEDBACK_MODAL_FOR_WEB_APP = true
// used local var instead of env till added to jenkins
const VUE_APP_DISABLE_FEEDBACK_MODAL_FOR_CROSS_PLATFORM = true

const { calculateExternalEnvironmentElementsHeight, appReference } =
  useExternalEnvironmentElementsHeight()

const store = useStore()
const isPluginCloud = computed(() => store.getters['pluginOptions/isPluginCloud'])
const isPluginServer = computed(() => store.getters['pluginOptions/isPluginServer'])
const licenseStatusId = computed(
  () => store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.LICENSE_STATUS_ID]
)
const licenseStatusDaysLeft = computed(
  () => store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.LICENSE_STATUS_DAYS_LEFT]
)
const selectedWorkspaceId = computed(() => store.state.workspaces.workspaceId)
const isWorkspaceSelected = computed(() => selectedWorkspaceId.value !== null)

const isLicenseWillExpire = computed(() => {
  return licenseStatusDaysLeft.value <= 14 && licenseStatusDaysLeft.value >= 0
})

const isWebApp = inject(isWebAppInjectionKey)
const isJiraApp = inject(isJiraAppInjectionKey)
const isCrossPlatformApp = inject(isCrossPlatformAppInjectionKey)

const isNoLicense = computed(() => licenseStatusId.value === LICENSE_TYPES.JIRA_NONE)
const menuPinned = computed(() => store.state.system.appMenu.pinned)

const webAppLicenseIsExpired = computed(
  () =>
    isWebApp &&
    [
      LICENSE_TYPES.WEB_APP_NONE,
      LICENSE_TYPES.WEB_APP_BLOCKED,
      LICENSE_TYPES.WEB_APP_CANCELED
    ].includes(licenseStatusId.value)
)

const crossPlatformAppLicenseIsExpired = computed(
  () =>
    isCrossPlatformApp &&
    [
      LICENSE_TYPES.CROSS_PLATFORM_APP_NONE,
      LICENSE_TYPES.CROSS_PLATFORM_APP_BLOCKED,
      LICENSE_TYPES.CROSS_PLATFORM_APP_CANCELED
    ].includes(licenseStatusId.value)
)

const limitLicenseAccess = computed(() => {
  return isNoLicense.value || webAppLicenseIsExpired.value || crossPlatformAppLicenseIsExpired.value
})

const route = useRoute()

const instanceStateId = computed(
  () => store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.INSTANCE_STATE_ID]
)

const isInstanceBlocked = computed(() => {
  return isInstanceStateBlocked({ instanceStateId: instanceStateId.value })
})

const appClasses = computed(() => {
  return {
    'app-App': true,
    'app-App-menu-pinned': menuPinned.value
  }
})

const fullAppLoading = computed(() => store.state.system.fullAppLoading)

const fullScreen = computed(() => store.state.system.fullscreen)

const isShowAside = computed(() => {
  return (
    settingsAreLoaded.value &&
    !limitLicenseAccess.value &&
    !isShowOnboarding.value &&
    !isShowCreateOrganization.value &&
    !isInstanceBlocked.value
  )
})

const pageClasses = computed(() => {
  if (!route.name || !settingsAreLoaded.value) return {}
  return {
    'app-PageMain': true,
    'app-PageMain-pinned-menu': menuPinned.value,
    'app-PageMain-full-screen':
      fullScreen.value || isShowOnboarding.value || isShowCreateOrganization.value,
    'app-PageMain-no-license': limitLicenseAccess.value,
    'app-PageMain-instance-blocked': isInstanceBlocked.value
  }
})

const licenseDaysLeft = computed(() => {
  return licenseStatusDaysLeft.value || addDaysToNow(0)
})

const isLicenseTrial = computed(() => licenseStatusId.value === LICENSE_TYPES.JIRA_TRIAL)

const { t } = useI18n()
const topLicenseBannerContent = computed(() => {
  if (isPluginCloud.value) {
    return t('banner.license_expired')
  }
  if (isLicenseTrial.value) {
    return t('banner.trial_subscribe', {
      licenseDaysLeft: licenseDaysLeft.value
    })
  }
  return t('banner.license_renew', {
    licenseDaysLeft: licenseDaysLeft.value
  })
})

const otWrapperHeight = computed(() => store.state.system.otWrapperHeight)
const pageHeaderHeight = computed(() => store.state.system.pageHeaderHeight)
const showAppLicenseBanner = computed(() => {
  if (isWebApp) {
    return false
  }

  if (isCrossPlatformApp) {
    return false
  }

  if (limitLicenseAccess.value) {
    return false
  }
  if (isPluginCloud.value) {
    return [LICENSE_TYPES.JIRA_NONE, LICENSE_TYPES.JIRA_TRIAL].includes(licenseStatusId.value)
  }
  return isLicenseWillExpire.value
})

// const APP_LICENSE_BANNER_HEIGHT = showAppLicenseBanner.value ? 56 : 0
const APP_LICENSE_BANNER_HEIGHT = 0

watch(
  showAppLicenseBanner,
  () => {
    store.dispatch('system/setAppLicenseBannerHeight', APP_LICENSE_BANNER_HEIGHT)
  },
  { immediate: true }
)

const externalEnvironmentElementsHeight = computed(
  () => store.state.system.externalEnvironmentElementsHeight
)

const appStyle = computed(() => {
  return {
    '--app-license-banner-height': `${APP_LICENSE_BANNER_HEIGHT}px`,
    '--external-environment-navbar-height': `${externalEnvironmentElementsHeight.value.navbar}px`,
    '--external-environment-footer-height': `${externalEnvironmentElementsHeight.value.footer}px`,
    // '--server-jira-navbar-scrolled-out-val': `${serverJiraNavbarScrolledOutVal.value}px`,
    '--objectives-toolbar-height': `${otWrapperHeight.value}px`,
    '--objectives-page-header-height': `${pageHeaderHeight.value}px`
  }
})

const onboarding = computed(() => store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.ONBOARDING])

const onKeydown = event => {
  if (isEscape(event.keyCode)) {
    // esc
    event.preventDefault()
    event.stopPropagation()
    event.stopImmediatePropagation()
    raiseKeydownEvent('esc', event)
  }
}

const showGreeting = () => {
  console.log(
    '%c Oboard ',
    `font-weight: 700;
     font-size: 40px;
     color: #16161d;
     font-family: Gilroy, sans-serif;
     border-radius: 100px;
     background: radial-gradient(90.65% 1051.93% at 4.68% 95.33%, #FF8541 4.29%, #F64963 21.78%, #A769F6 78.71%, #5879EE 100%)`,
    '\n\n',
    ' https://www.oboard.io'
  )
}

onMounted(() => {
  showGreeting()

  if (!IS_DEVELOPMENT) {
    calculateExternalEnvironmentElementsHeight()

    // hide footer on server jira
    if (isPluginServer.value) {
      nextTick(() => {
        const serverFooter = document.querySelector('footer#footer')
        if (serverFooter) {
          serverFooter.style.display = 'none'
        }
      })
    }
  }

  if (onboarding.value) {
    tracker.logEvent('First visit', {
      category: EVENT_CATEGORIES.VISIT
    })
  }

  if (!isLocalStorageAvailable()) {
    showNotify({
      content: `
            <p>
              Looks like third-party local storages are disabled in browser. OKR board
              requires it to work properly.
            </p>
            <p class="o-toast-last-paragraph">
              Read the
              <a
                href="https://oboard.atlassian.net/l/c/qikwTw3J"
                target="_blank"
              >
                article
              </a>
              on how to fix it or contact
              <a href="mailto:support@oboard.io">support</a>.
            </p>
        `,
      type: NOTIFICATION_TYPES.WARNING,
      isHtml: true,
      duration: NOTIFICATION_DURATIONS.LONG,
      withButtonClose: true
    })
  }

  const storedMenuPinnedStatus = readFromStorageByKey(MENU_PINNED_STATUS_LS_KEY)
  if (storedMenuPinnedStatus !== null) {
    store.dispatch('system/toggleMenuPinnedStatus', storedMenuPinnedStatus)
  }

  document.body.addEventListener('keydown', onKeydown)

  // load 'create jira issue' module for jira server(to be able to use default
  // create jira issue form)
  if (JIRA_SERVER_API) {
    if (window.WRM) {
      window.WRM.require(['wr!com.atlassian.jira.jira-quick-edit-plugin:quick-create-issue'])
      console.log('Loaded resource for creating jira issues in jira server')
    } else {
      if (IS_PRODUCTION) {
        console.warn('Fail to load resource for creating jira issues in jira server')
      }
    }
  }
})

onBeforeUnmount(() => {
  document.body.removeEventListener('keydown', onKeydown)
  if (document.body.classList.contains(STYLED_SCROLLBAR_CLASS)) {
    document.body.classList.remove(STYLED_SCROLLBAR_CLASS)
  }
})

if (!isSafari()) {
  document.body.classList.add(STYLED_SCROLLBAR_CLASS)
}
if (isSafari()) {
  document.body.classList.add(IS_SAFARI_CLASS)
}

// const rate = ref(0)

// const logFeedbackEvent = (selectedRate, message) => {
//   tracker.logEvent('feedback sent', {
//     category: EVENT_CATEGORIES.FEEDBACK,
//     label: selectedRate,
//     value: message
//   })
//   rate.value = 0
// }

// const showRateModal = ref(false)

// const onRateModalClose = () => {
//   showRateModal.value = false
//   logFeedbackEvent(0, '')
// }

// const onRateClick = item => {
//   showRateModal.value = false
//   showMessageModal.value = true
//   rate.value = item
// }

// const showThanksModal = ref(false)
//
// const onSendMessageClick = item => {
//   showMessageModal.value = false
//   showThanksModal.value = true
//   logFeedbackEvent(rate.value, item)
// }

// const showMessageModal = ref(false)
//
// const onMessageModalClose = () => {
//   showMessageModal.value = false
//   logFeedbackEvent(rate.value, '')
// }

const menuShowed = computed(() => store.state.system.appMenu.showed)

const onAsideMouseLeave = () => {
  if (!menuPinned.value && menuShowed.value) {
    store.dispatch('system/toggleMenuShowedStatus', false)
  }
}

const settingsAreLoaded = computed(() => store.state.system.settingsAreLoaded)

watch(
  () => fullAppLoading.value.flag,
  newValue => {
    const LOADING_CLASS = 'full-app-loading'
    if (newValue) {
      document.body.classList.add(LOADING_CLASS)
    } else {
      document.body.classList.remove(LOADING_CLASS)
    }
  }
)

const appPlatform = inject(appPlatformInjectionKey)

const releaseUpdatesModalId = computed(() => store.getters['system/releaseUpdatesModalId'])
const feedBackModalId = computed(() => store.getters['system/feedBackModalId'])
const appOpenedCount = computed(() => store.getters['system/appOpenedCount'])

const isShowFeedbackModal = ref(false)

const closeFeedbackModal = () => {
  isShowFeedbackModal.value = false
}

const showFeedbackModal = () => {
  const isShowReleaseUpdatesModal =
    releaseUpdatesModalId.value < DEFAULT_USERS_SETTINGS[RELEASE_UPDATES_MODAL_ID]

  const preconditionsToShowModal =
    !isShowReleaseUpdatesModal &&
    appOpenedCount.value >= REQUIRED_APP_OPENED_COUNT_TO_SHOW_MODAL &&
    !isPluginServer.value

  const isUserSeenFeedbackModal =
    feedBackModalId.value === DEFAULT_USERS_SETTINGS[FEEDBACK_MODAL_ID]

  const isFeedbackModalDisabled =
    (isWebApp && VUE_APP_DISABLE_FEEDBACK_MODAL_FOR_WEB_APP) ||
    (isCrossPlatformApp && VUE_APP_DISABLE_FEEDBACK_MODAL_FOR_CROSS_PLATFORM) ||
    (isJiraApp && VUE_APP_DISABLE_FEEDBACK_MODAL_FOR_JIRA)

  if (preconditionsToShowModal && !isUserSeenFeedbackModal && !isFeedbackModalDisabled) {
    isShowFeedbackModal.value = true

    store.dispatch('system/updateUserSettings', {
      [USER_SETTINGS_MAPPER[FEEDBACK_MODAL_ID]]: DEFAULT_USERS_SETTINGS[FEEDBACK_MODAL_ID]
    })
  }
}

// const initClarity = () => {
//   if (!IS_DEVELOPMENT && !isPluginServer.value) {
//     clarity.start({
//       projectId: 'mk3ivuck2o',
//       upload: 'https://m.clarity.ms/collect',
//       track: true,
//       content: true
//     })
//
//     clarity.consent()
//   }
// }

const router = useRouter()

const missedPageViewEvents = ref([])

const collectMissedPageViewEvents = ({ event } = {}) => {
  if (!settingsAreLoaded.value) {
    missedPageViewEvents.value.push({
      ...event
    })
  }
}

const logPageViewGtmEvent = ({ event } = {}) => {
  gtmTracker.logEvent(EVENT_NAMES.PAGE_VIEW, { ...event })
}

router.afterEach((to, from) => {
  if (to.name !== from.name) {
    if (!isPluginServer.value) {
      const payload = {
        [EVENT_KEYS.PAGE_URL]: window?.location?.href || to.fullPath,
        [EVENT_KEYS.PAGE_NAME]: to.name
      }

      if (isWebApp || IS_DEVELOPMENT) {
        collectMissedPageViewEvents({ event: payload })

        logPageViewGtmEvent({ event: payload })
      }

      if (isJiraApp && !IS_DEVELOPMENT) {
        JIRA_CLOUD_API.getLocation(location => {
          payload[EVENT_KEYS.PAGE_URL] = location

          collectMissedPageViewEvents({ event: payload })

          logPageViewGtmEvent({ event: payload })
        })
      }
    }
  }
})

watch(settingsAreLoaded, async () => {
  await store.dispatch('system/increaseAppOpenedCount')
  handleOnboardingHub()

  const baseUrl = computed(() => store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.BASE_URL])
  const userData = computed(() => store.state.system.userData)
  const isJiraConnected = computed(
    () => store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.JIRA_CONNECTED]
  )

  const userLocale = computed(() => store.getters['system/appLocale'])

  const platformForTracking = {
    [APP_PLATFORMS.JIRA]: 'jira cloud',
    [APP_PLATFORMS.WEB_APP]: 'web app'
  }

  const integration = isWebApp && isJiraConnected.value ? [APP_PLATFORMS.JIRA.toLowerCase()] : []

  tracker.setUserData({
    instance: baseUrl.value,
    license: licenseStatusId.value,
    lastSeen: dayjs().format(),
    source_platform: platformForTracking[appPlatform] || 'unknown',
    integration,
    account_id: userData.value?.userAccountId,
    onboarding: onboarding.value,
    locale: userLocale.value
  })

  const iss = computed(() => {
    return (
      store.state.appContext.selectedOrganizationId ||
      store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.ISS]
    )
  })

  const isNeedGtmTrackerInit = isWebApp || (isJiraApp && !isPluginServer.value)

  if (isNeedGtmTrackerInit) {
    gtmTracker.init({
      instanceId: iss.value,
      email: userData.value?.email,
      isPluginAdmin: isPluginAdmin.value,
      isWorkspaceAdmin: isWorkspaceAdmin.value
    })

    if (!isEmpty(missedPageViewEvents.value)) {
      missedPageViewEvents.value.forEach(event => {
        logPageViewGtmEvent({ event })
      })
    }

    missedPageViewEvents.value = []
  }

  const userGroups = computed(() => store.state.system.userData.userGroups)

  if (!isEmpty(userGroups.value)) {
    const groupsList = uniq(
      userGroups.value
        .filter(group => group.workspaceId === store.state.workspaces.workspaceId)
        .map(({ name }) => name.trim())
    )

    const source = route.query.referer || ''
    tracker.logEvent('app opened', {
      category: EVENT_CATEGORIES.MAIN_NAVIGATION,
      group: groupsList,
      user: userData.value.userAccountId,
      source
    })
    // store.dispatch('system/deleteUserDataParameter', 'userGroups')
  }

  // const tracingDisabled = computed(() => store.getters['system/tracingDisabled'])
  //if (!tracingDisabled.value) {
  //  initClarity()
  //}
})

const isWorkspaceAdmin = computed(
  () => store.state.system.userData.hasAccessToWorkspaceSettingsPage
)
const isPluginAdmin = computed(() => store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.IS_PLUGIN_ADMIN])
const onboardingHubState = computed(() => store.getters['system/onboardingHubState'])

const isShowOnboarding = ref(false)

const isShowCreateOrganization = computed(
  () => store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.CREATE_ORGANIZATION]
)

const onCloseCreateOrganization = () => {
  store.dispatch('pluginOptions/setPluginOptions', {
    createOrganization: false
  })
}
const handleOnboardingHub = () => {
  // isShowOnboarding.value = true
  const { userOnboardingPassed, adminOnboardingPassed } = onboardingHubState.value

  const needToShowAdminOnboarding = isPluginAdmin.value && !adminOnboardingPassed

  const needToShowWsAdminOnboarding =
    !isPluginAdmin.value && isWorkspaceAdmin.value && !adminOnboardingPassed

  const needToShowUserOnboarding =
    !isPluginAdmin.value && !isWorkspaceAdmin.value && !userOnboardingPassed

  if (needToShowAdminOnboarding || needToShowUserOnboarding || needToShowWsAdminOnboarding) {
    isShowOnboarding.value = true
  }

  if (!isShowOnboarding.value) {
    showFeedbackModal()
  }
}

// const isShowTour = computed(() => {
//   return (
//     onboarding.value &&
//     !isNoLicense.value &&
//     !webAppLicenseIsExpired.value &&
//     !isShowOnboarding.value &&
//     !isShowCreateOrganization.value
//   )
// })
</script>

<style lang="scss">
body {
  overscroll-behavior-x: none;
  overflow-x: hidden;
}
</style>

<style lang="scss" scoped>
.app-App {
  min-height: calc(100vh - var(--external-environment-navbar-height));
  min-width: 860px;
  position: relative;
  z-index: 101;
  background: $white;

  &-menu-pinned {
    min-width: 1100px;
  }
}

.o-toast-title {
  font-weight: fw('medium');
}

.o-toast-last-paragraph {
  margin-bottom: 0;
}

// make sticky header and navigation over content on scrolling
//.ml-LayoutHeader {
//  z-index: 10;
//  position: sticky;
//  top: 0;
//  left: 0;
//  display: flex;
//  flex-direction: column;
//
//  &-menu-pinned {
//    width: calc(100% - #{$menu-width});
//    margin-left: auto;
//  }
//}

.app-Aside {
  position: absolute;
  height: 100%; // calc(100% - var(--external-environment-navbar-height));
  left: 0;
  top: 0; // var(--external-environment-navbar-height);
  transition: transform $menu-transition;
  // transform: translateX(-90%);
  transform: translate3d(calc(#{$unpinned-menu-width} - #{$menu-width}), 0, 0);
  width: $menu-width;
  background: $white;
  z-index: $menu-z-index;

  &-pinned,
  &-showed {
    transform: translate3d(0, 0, 0);
  }
}

.app-PageMain {
  height: 100%;
  min-height: 100%;
  // padding-left: calc(#{$menu-width} * 0.1);
  padding-left: $unpinned-menu-width;
  transition: padding-left $menu-transition;

  --menu-width: #{$unpinned-menu-width};

  &-pinned-menu {
    padding-left: $menu-width;
    --menu-width: #{$menu-width};
  }

  &-full-screen,
  &-no-license,
  &-instance-blocked {
    padding-left: 0;
    --menu-width: 0;
  }
}
</style>

<style lang="scss">
@import '~@/assets/styles/mixins';

/* .o-navigation + .o-inner-page {
  margin-top: 64px; // o-navigation has position: fixed, avoid overflow
} */

html,
body,
#app {
  min-height: 100%;
  @include styled-native-scrollbar();
}

html {
  @include styled-native-scrollbar(8, $grey-2, $grey-10, true);
}

.o-inner-page {
  display: flex;
  flex-direction: column;
}

.tippy-box[data-theme~='dark'] {
  font-size: $fs-12;
  line-height: 14px;
  padding: 4px 8px;
}

.o-page-navigation > .o-navigation {
  // fix height of sticky element to avoid auto calculated float values that can cause problems
  height: 65px;
}

.app-App * {
  outline: none;
}
</style>
