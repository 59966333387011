import {
  SET_ORGANIZATION_ID,
  SET_IS_WEB_APP,
  SET_URLS,
  SET_ORGANIZATIONS,
  UPDATE_ORGANIZATION,
  SET_IS_CONNECTION_INITIALIZED_BY_ME,
  SET_IS_DISCONNECTION_INITIALIZED_BY_ME,
  SET_IS_CROSS_PLATFORM_APP,
  SET_DISABLED_MODULES,
  SET_EXTERNAL_PLATFORM_ID
} from '@/store/mutation-types'
import { isModuleDisabled } from '@root/disabled-moduels'
import { EXTERNAL_PLATFORM_IDS, isBuiltInMonday } from '@root/external-platforms'

const normalizeOrganization = organization => {
  const { iss, color, name } = organization
  const [prefix] = Array.from(name.trim().toUpperCase()) // only Array.from works correctly if we have a string starting with emoji
  return {
    ...organization,
    id: iss,
    color: color || '#000000',
    prefix
  }
}

const state = {
  selectedOrganizationId: null,
  organizations: [],
  isWebApp: false,
  isCrossPlatformApp: false,
  urls: {
    webAppUrl: null,
    webAppLoginUrl: null
  },
  connectionWithJira: {
    isConnectionInitializedByMe: false,
    isDisconnectionInitializedByMe: false
  },
  disabledModules: [],
  externalPlatformId: EXTERNAL_PLATFORM_IDS.UNKNOWN
}

const mutations = {
  [SET_ORGANIZATION_ID](state, value) {
    state.selectedOrganizationId = value
  },

  [SET_ORGANIZATIONS](state, value) {
    state.organizations = value.map(normalizeOrganization)
  },

  [UPDATE_ORGANIZATION](state, value) {
    const index = state.organizations.findIndex(instance => instance.id === value.iss)
    if (index !== -1) {
      state.organizations.splice(index, 1, normalizeOrganization(value))
    }
  },

  [SET_URLS](state, value) {
    state.urls = value
  },

  [SET_IS_WEB_APP](state) {
    state.isWebApp = true
  },

  [SET_IS_CROSS_PLATFORM_APP](state) {
    state.isCrossPlatformApp = true
  },

  [SET_IS_CONNECTION_INITIALIZED_BY_ME](state, value) {
    state.connectionWithJira.isConnectionInitializedByMe = Boolean(value)
  },

  [SET_IS_DISCONNECTION_INITIALIZED_BY_ME](state, value) {
    state.connectionWithJira.isDisconnectionInitializedByMe = Boolean(value)
  },

  [SET_DISABLED_MODULES](state, value) {
    state.disabledModules = value
  },

  [SET_EXTERNAL_PLATFORM_ID](state, value) {
    if (
      state.externalPlatformId !== value &&
      Object.values(EXTERNAL_PLATFORM_IDS).includes(value)
    ) {
      state.externalPlatformId = value
    }
  }
}

const getters = {
  selectedOrganization: state => {
    return (
      state.organizations.find(instance => instance.id === state.selectedOrganizationId) || null
    )
  },

  isModuleDisabled:
    state =>
    ({ moduleId } = {}) => {
      return isModuleDisabled({ disabledModules: state.disabledModules, moduleId })
    },

  isBuiltInMonday: state => {
    return isBuiltInMonday(state.externalPlatformId)
  }
}

const actions = {
  setOrganization({ commit }, value) {
    commit(SET_ORGANIZATION_ID, value)
  },

  setOrganizations({ commit }, value) {
    commit(SET_ORGANIZATIONS, value)
  },

  updateOrganization({ commit }, value) {
    commit(UPDATE_ORGANIZATION, value)
  },

  setUrls({ commit }, value) {
    commit(SET_URLS, value)
  },

  setIsWebApp({ commit }) {
    commit(SET_IS_WEB_APP)
  },

  setIsCrossPlatformApp({ commit }) {
    commit(SET_IS_CROSS_PLATFORM_APP)
  },

  setIsConnectionInitializedByMe({ commit }, value) {
    commit(SET_IS_CONNECTION_INITIALIZED_BY_ME, value)
  },

  setIsDisconnectionInitializedByMe({ commit }, value) {
    commit(SET_IS_DISCONNECTION_INITIALIZED_BY_ME, value)
  },

  setDisabledModules({ commit }, value) {
    commit(SET_DISABLED_MODULES, value)
  },

  setExternalPlatformId({ commit }, value) {
    commit(SET_EXTERNAL_PLATFORM_ID, value)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
