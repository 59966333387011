const EXTERNAL_PLATFORM_IDS = {
  UNKNOWN: 'unknown',
  SALESFORCE: 1,
  MONDAY: 2
}

const isBuiltInMonday = (externalPlatformId = EXTERNAL_PLATFORM_IDS.UNKNOWN) =>
  externalPlatformId === EXTERNAL_PLATFORM_IDS.MONDAY

module.exports = {
  EXTERNAL_PLATFORM_IDS,
  isBuiltInMonday
}
